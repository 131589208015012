import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout.en"
import SEO from "../components/seo"

import en from 'react-intl/locale-data/en';
import 'intl/locale-data/jsonp/en';


const IndexPage = (props) => (
  <Layout  location={props.location}>
    <SEO title="Home" lang={'en'}/>

    <p>Welcome.</p>
    <p>
        Bine ați venit pe pagina noastră de <Link to="/analizator/">analiză gramaticală</Link> în limba română!
    </p>
    <p>
        Aveți la dispoziție primul <Link to="/analizator/">analizator gramatical</Link> automat,
        cu ajutorul căruia puteți analiza construcții mai dificile, fraze sau chiar texte prin simpla scriere sau
        copiere a acestora în fereastra disponibilizată. Trebuie să folosiți însă scrierea cu diacritice pentru o analiză de calitate!
    </p>
    <p>
        În actuala versiune, <Link to="/analizator/">analizatorul</Link> scoate în evidență verbele, dar și unele cuvinte strâns legate de acestea (pronume, adverbe),
        restul cuvintelor primind etichete morfosintactice, fără ca ambiguitățile să fie tratate în toate cazurile.
        În funcție de interesul și de sugestiile pe care le așteptăm de la voi, analizatorul va aprofunda analiza
        tuturor categoriilor morfologice ale limbii române.
    </p>
    <p>
      Analiza automată nu poate atinge suplețea analizei umane, fiind dificil de stabilit reguli de dezambiguizare în toate contexte.
        De aceea, analizatorul trebuie privit ca un instrument incitant, care vă invită să găsiți soluții, pentru a face ca acesta să se
        apropie tot mai mult de perfecțiune.
    </p>
    <p>
      Gramatica pe care se sprijină analizatorul este gramatica tradițională, predată în școli și prezentă în clasificarea cuvintelor
        din dicționarele curente ale limbii române. La <Link to="/resurse/">Resurse</Link> veți găsi cele mai bune legături pentru limba română.
    </p>
    <p>
        Aveți la dispoziție un <Link to="/forum/">forum</Link> pentru a cere lămuriri sau pentru a discuta între voi.
        Ne rezervăm dreptul de a răspunde în funcție
        de pertinența întrebărilor și de timpul pe care îl putem aloca.
    </p>
    <p>
      Sesiune plăcută!
    </p>

  </Layout>
)

export default IndexPage
